import {jsPDF} from 'jspdf'
import removeAccents from 'remove-accents'
import {TProductTemplate} from '../api/product-templates'
import {getBarCode} from './barcode'
import {getLinkQrCode} from './qr'
import {blobToDataURL, getImageDimensions} from './utils'
import {CHEAP_PRICE_LIMIT, PRINT_HEIGHT, PRINT_INITIAL_PRICE, PRINT_LOGO, PRINT_WIDTH, VAT} from '../../config'

const PAD = 0.5
const WIDTH = PRINT_WIDTH
const HEIGHT = PRINT_HEIGHT
const TEXT_HEIGHT = 2.7
const QR_SIZE = 2.2
const BARCODE_SIZE = 1

async function docInsertLabel(doc: jsPDF, product: TProductTemplate) {
  // palet no
  doc.setFontSize(7)
  doc.text(`${product.palet_no}`, PAD + 4, PAD, {maxWidth: 2})

  // QR Code
  let Y = PAD
  doc.addImage(await getLinkQrCode(product), 'jpg', PAD, Y, QR_SIZE, QR_SIZE)
  doc.setFontSize(11)
  doc.text('Scaneaza aici!', PAD + QR_SIZE + 0.1, Y + QR_SIZE / 2, {
    maxWidth: 2,
  })

  // Description
  Y += QR_SIZE + PAD
  doc.setFontSize(9)
  const text = removeAccents(product.name || product.description_sale || product.description)
  doc.text(text, PAD, Y, {maxWidth: WIDTH - 2 * PAD})
  doc.setFillColor('white')
  doc.rect(0, Y + TEXT_HEIGHT, WIDTH, HEIGHT - 2 * PAD, 'F')

  // Initial price
  if (product.list_price * VAT > CHEAP_PRICE_LIMIT && PRINT_INITIAL_PRICE)
    doc.text(`Pret initial: ${Math.ceil(product.list_price * VAT)} RON`, PAD, Y + TEXT_HEIGHT, {maxWidth: WIDTH - 2 * PAD})

  // Barcode
  Y = 6
  if (product.barcode) {
    const barcode = getBarCode(product.barcode)
    const size = await getImageDimensions(barcode)
    doc.addImage(barcode, 'jpg', PAD, Y, (size.w / size.h) * BARCODE_SIZE, BARCODE_SIZE)
  }

  // Logo
  Y = 7
  if (PRINT_LOGO) {
    const logo = await fetch('/logo.png')
    const imgData = await blobToDataURL(await logo.blob())
    doc.addImage(imgData, 'png', PAD, Y, WIDTH - PAD * 2, HEIGHT - Y - PAD)
  }
}

export async function createLabel({product}: {product: TProductTemplate}) {
  const doc = new jsPDF({unit: 'cm', format: [WIDTH, HEIGHT]})
  await docInsertLabel(doc, product)

  return doc.save(`${product.barcode}.pdf`)
}

export async function createPaletLabelsPDF(palet, {products}: {products: TProductTemplate[]}) {
  const doc = new jsPDF({unit: 'cm', format: [WIDTH, HEIGHT]})

  let total = 0
  for (let i = 0; i < products.length; i++) {
    await docInsertLabel(doc, products[i])
    total += products[i].list_price * VAT
    await doc.addPage()
  }

  doc.addPage()
  doc.text(`Pallet ${palet}`, PAD, 1)
  doc.text(`Total: ${total.toFixed(2)}`, PAD, 3)

  return doc.save(`${palet}.pdf`)
}
