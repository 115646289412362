import {Link} from '@mui/material'
import {useState} from 'react'
import {WEBSITE_URL} from '../config'
import {useProductStatus} from '../lib/api/product-status'
import {TProductTemplate} from '../lib/api/product-templates'

function SimpleProductList({title, products}: {title: string; products?: Array<TProductTemplate>}) {
  return (
    <>
      <h2 className="text-xl">{title}</h2>

      {products?.map((p) => (
        <div key={String(p.id)}>
          <Link href={`${WEBSITE_URL}${p.website_url}`} target="_blank">
            <p>{`${p.id} - ${p.asin} (retries ${p.dashy_tries})`}</p>
          </Link>
        </div>
      ))}
      <br />
    </>
  )
}

export default function Status() {
  const {data, isValidating, mutate} = useProductStatus({
    refreshInterval: 10 * 1000,
  })

  const [showPending, setShowPending] = useState(false)
  const [showZero, setShowZero] = useState(false)
  const togglePending = () => setShowPending((state) => !state)
  const toggleZero = () => setShowZero((state) => !state)

  if (!data && isValidating) return <div>Loading</div>

  return (
    <div className="w-full">
      <h1 className="text-xl font-bold">
        Product status{' '}
        <span onClick={() => mutate()} className="cursor-pointer">
          {isValidating ? '...' : '↻'}
        </span>
      </h1>
      <div className="grid grid-cols-2 gap-2 max-w-lg">
        <div>In Odoo</div>
        <div>{data?.all}</div>

        <div>Processing</div>
        <div>{data?.processing}</div>

        <div>To be processed</div>
        <div>
          {data?.pendingCount}{' '}
          <span onClick={togglePending} className="cursor-pointer underline">
            View
          </span>
        </div>

        <div>Price ZERRO</div>
        <div>
          {data?.zeroPriceProducts?.length}{' '}
          <span onClick={toggleZero} className="cursor-pointer underline">
            View
          </span>
        </div>

        <div>Asins infos</div>
        <div>{data?.asins}</div>
      </div>

      {showPending && <SimpleProductList title="Pending prodcuts" products={data?.pendingProducts} />}
      {showZero && <SimpleProductList title="Zero price prodcuts" products={data?.zeroPriceProducts} />}
    </div>
  )
}
