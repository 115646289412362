import {useState} from 'react'
import {toast} from 'react-toastify'
import Button from '../components/Button'
import Input from '../components/Input'
import {useAuth} from '../context/auth'
import {login} from '../lib/api/auth'

export default function Login() {
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const {refreshLogin} = useAuth()

  const onSubmit = (e) => {
    login({username, password})
      .then(() => {
        toast.success('Logged in')
        refreshLogin()
        setTimeout(() => window.location.reload(), 300)
      })
      .catch(() => {
        toast.error('Invalid credentials')
      })
    e.preventDefault()
  }

  return (
    <div className="flex flex-col w-full items-center p-10 text-primary bg-secondary">
      <form onSubmit={onSubmit}>
        <div className="flex flex-col gap-2">
          <Input placeholder="Username" onChange={(e) => setUsername(e.target.value)} />
          <Input placeholder="Password" type="password" onChange={(e) => setPassword(e.target.value)} />
          <Button type="submit">Login</Button>
        </div>
      </form>
    </div>
  )
}
