import QRCode from 'qrcode'
import {QR_SHORT_URL} from '../../config'
import {TProductTemplate} from '../api/product-templates'

export function getLinkQrCode(product: TProductTemplate) {
  return QRCode.toDataURL(`${QR_SHORT_URL}${product.barcode}`, {
    // `${WEBSITE_URL}${product.website_url}`
    type: 'image/jpeg',
    rendererOpts: {quality: 1},
    maskPattern: 5,
    margin: 0,
  })
}
