import React, {useEffect, useRef} from 'react'
import {Html5Qrcode} from 'html5-qrcode'

async function getCameras(): Promise<string> {
  try {
    const cams = await Html5Qrcode.getCameras()
    if (!cams.length) {
      alert('No cameras available')
      throw new Error('No cameras available')
    }
    return cams[0].id
  } catch (err) {
    alert(`Please allow camera access: ${err}`)
    throw err
  }
}

export default function BarcodeScanner({onScanned, closeOnSuccess = true}) {
  const qrcode = useRef<{obj: any; start: Promise<null> | null}>({obj: null, start: null})

  useEffect(() => {
    let start
    const obj = qrcode.current.obj || new Html5Qrcode('reader')
    qrcode.current.obj = obj

    function onScanSuccess(decodedText, decodedResult) {
      console.log(`Code matched = ${decodedText}`, decodedResult)
      onScanned({decodedText, decodedResult})
      if (closeOnSuccess) {
        qrcode.current.obj.stop()
      }
    }

    function onScanFailure(error) {
      console.error('[Barcode Scanner] Error: ', error)
    }

    getCameras().then((cameraId) => {
      start = obj.start(
        {facingMode: 'environment'},
        {
          fps: 10,
          qrbox: {width: 350, height: 250},
          // supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
        },
        onScanSuccess,
        onScanFailure
      )

      qrcode.current.start = start
    })

    return () => {
      qrcode.current.start?.then(() => {
        qrcode.current.obj.stop()
      })
    }
  }, [])

  return (
    <div className="w-full">
      <div id="reader" className="w-full"></div>
    </div>
  )
}
