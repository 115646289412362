import {AxiosResponse} from 'axios'
import {execute_kw} from './client'

export type TWarehouse = {
  id: number
  name?: string
  view_location_id: [number, string]
  lot_stock_id: [number, string]
}

let cache: AxiosResponse<Array<TWarehouse>> | null = null

export function getWarehouses(): Promise<AxiosResponse<Array<TWarehouse>>> {
  const res = execute_kw('stock.warehouse', 'search_read', [], {limit: 200})
  res.then((x) => {
    cache = x
  })
  return res
}

export function getWarehousesCached(): Promise<AxiosResponse<Array<TWarehouse>>> {
  if (cache) return Promise.resolve(cache)
  return getWarehouses()
}
