import cx from 'classnames'
import style from './Button.module.css'

type ButtonProps = {
  onClick?: () => unknown
  type?: 'submit' | 'button'
  variant?: 'primary' | 'success' | 'link'
  className?: string
  disabled?: boolean
  children: any
}

export default function Button({onClick, type = 'button', variant = 'primary', className = '', disabled, children}: ButtonProps) {
  return (
    <button {...{disabled, type}} className={cx(style.base, style[variant], className)} onClick={onClick}>
      {children}
    </button>
  )
}
