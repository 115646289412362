import {useState} from 'react'
import {useWarehouse} from '../../context/warehouse'
import {createPaletLabelsPDF} from '../label'
import {TProductTemplate, productReception} from '../api/product-templates'
import {TWarehouse} from '../api/warehouse'

export function usePrintAll({products}: {products?: TProductTemplate[]}) {
  const [progress, setProgress] = useState(-1)
  const {warehouse} = useWarehouse()

  const printAllProducts = async () => {
    if (!products?.length) return

    for (let i = 0; i < products.length; i++) {
      const product = products[i]
      const palet_no = i + 1
      await productReception(product, {is_published: true, palet_no, warehouse: warehouse as TWarehouse})
      setProgress(i)
    }

    createPaletLabelsPDF(products[0].palet, {products})
    setProgress(-1)
  }

  return {printAllProducts, loading: progress >= 0, progress, target: products?.length}
}
