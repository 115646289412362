import barcode from 'jsbarcode'

export function getBarCode(text: string) {
  const canvas = document.createElement('canvas')

  barcode(canvas, text, {
    format: 'CODE128',
    width: 3,
    height: 50,
  })
  const data = canvas.toDataURL('image/jpeg', 1)
  canvas.remove()

  return data
}
