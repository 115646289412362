import {useState} from 'react'
import {toast} from 'react-toastify'
import {VAT} from '../config'
import {productChangePrice, TProductTemplate} from '../lib/api/product-templates'
import {roundToTwo} from '../lib/utils/number'
import Button from './Button'
import Input from './Input'

export default function PriceChanger({product, refreshSearch}: {product: TProductTemplate; refreshSearch: () => void}) {
  const [price, setPrice] = useState<number>(roundToTwo(product.list_price * VAT))

  const changePrice = async () => {
    try {
      await productChangePrice(product, price / VAT)
      toast.success('Prețul a fost schimbat cu succes')
      refreshSearch()
    } catch (error) {
      console.error(error)
      toast.error('Eroare la schimbarea prețului')
    }
  }

  return (
    <div className="grid grid-cols-7 lg:grid-cols-9 gap-2 mb-2">
      <Input className="col-span-5 lg:col-span-7" type="number" value={price} onChange={(e) => setPrice(Number(e.target.value))} />
      <Button className="col-span-2" onClick={changePrice}>
        Schimbă preț
      </Button>
    </div>
  )
}
