import {createContext, useState, useContext, useEffect} from 'react'

import {TWarehouse, getWarehouses} from '../lib/api/warehouse'

type TWarehouseContext = {
  warehouse?: TWarehouse
  warehouses?: TWarehouse[]
  setWarehouse: (warehouse?: TWarehouse) => unknown
}

const WarehouseContext = createContext<TWarehouseContext>({
  warehouse: undefined,
  setWarehouse: () => null,
})

export function WarehouseProvider({children}) {
  const [warehouse, setWarehouse] = useState<TWarehouse | undefined>(undefined)
  const [warehouses, setWarehouses] = useState<Array<TWarehouse>>([])

  useEffect(() => {
    getWarehouses().then(({data}) => {
      setWarehouses(data?.filter?.((w) => w.name !== 'Main'))
      if (data[0]) setWarehouse(data[0])
    })
  }, [])
  // const reload = () => {
  //   getWarehouses().then(({data}) => setWarehouses(data))
  // }

  return <WarehouseContext.Provider value={{warehouse, warehouses, setWarehouse}}>{children}</WarehouseContext.Provider>
}

export const useWarehouse = () => useContext(WarehouseContext)
