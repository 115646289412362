import {createContext, useEffect, useState, useCallback, useContext} from 'react'
import {checkLogin} from '../lib/api/auth'

type TAuthContext = {
  loggedIn: boolean
  initialised: boolean
  refreshLogin: () => unknown
}

const AuthContext = createContext<TAuthContext>({
  loggedIn: false,
  initialised: false,
  refreshLogin: () => console.warn('Auth not initialised'),
})

export function AuthProvider({children}) {
  const [loggedIn, setLoggedIn] = useState(false)
  const [initialised, setInitialised] = useState(false)

  const refreshLogin = useCallback(() => {
    checkLogin()
      .then((res) => setLoggedIn(res.data.success))
      .catch(() => setLoggedIn(false))
      .finally(() => setInitialised(true))
  }, [])

  useEffect(() => {
    refreshLogin()
  }, [refreshLogin])

  return <AuthContext.Provider value={{refreshLogin, loggedIn, initialised}}>{children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext)
