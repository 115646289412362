import {useGet} from './client'
import {TProductTemplate} from './product-templates'

type TProductStatus = {
  pendingCount: number
  all: number
  asins: number
  processing: number
  pendingProducts: Array<TProductTemplate>
  zeroPriceProducts: Array<TProductTemplate>
}

export const useProductStatus = (config) => useGet<TProductStatus>('products.php?ac=status', config)
