import {AxiosPromise} from 'axios'
import {httpClient} from './client'

type TAuthResponse = {
  success: boolean
  username: string
}

export function login({username, password}): AxiosPromise<TAuthResponse> {
  return httpClient.post('login.php?ac=login', {username, password})
}

export function checkLogin(): AxiosPromise<TAuthResponse> {
  return httpClient.get('login.php?ac=check')
}
