import cx from 'classnames'
import {setProductCategory, TProductTemplate} from '../lib/api/product-templates'
import {usePriceCategories} from '../lib/api/public-categories'
import Button from './Button'
import {toast} from 'react-toastify'
import {VARIABLE_PERCENT_2_CATEGORY} from '../config'

export default function CategoryToggler({
  products,
  onEnd,
  onStart,
}: {
  products: TProductTemplate[]
  onEnd: () => void
  onStart?: () => void
}) {
  const categories = usePriceCategories()
  if (!categories.loaded) return <div>Loading ...</div>

  const ids = [...new Set(products.map((p) => p?.categ_id).flat())]
  console.log(ids)
  const onClick = async (category) => {
    try {
      onStart?.()
      for (const p of products) await setProductCategory(p, category, category !== categories.defect?.id)
      onEnd?.()
      toast('Produse actualizate')
    } catch (e) {
      toast('Eroare la actualizare produse')
    }
  }

  const options: {
    label: string
    category: any
    c: typeof categories.fixedPercent
  }[] = [
    {
      label: '% fix',
      category: categories.fixedPercent,
      c: '!bg-blue-500',
    },
    {
      label: '% var',
      category: categories.variablePercent,
      c: '!bg-yellow-500',
    },
    VARIABLE_PERCENT_2_CATEGORY && {
      label: '% var 2',
      category: categories.variablePercent2,
      c: '!bg-orange-500',
    },
    {
      label: 'pret fix',
      category: categories.fixedPrice,
      c: '!bg-red-500',
    },
    {
      label: 'pret mic',
      category: categories.smallPrice,
      c: '!bg-pink-500',
    },
    {
      label: 'defect',
      category: categories.defect,
      c: '!bg-gray-500',
    },
  ].filter(Boolean) as any

  return (
    <div className="flex flex-row gap-4">
      {options.map(({label, category, c}) => (
        <Button
          key={label}
          onClick={() => onClick(category?.id as number)}
          className={cx(c, ids.includes(category?.id ?? -1) && 'font-extrabold underline')}
        >
          {label}
        </Button>
      ))}
    </div>
  )
}
