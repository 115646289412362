import {useEffect, useState} from 'react'
import {execute_kw} from './client'

export type TProductCategory = {
  id: number
  name: string
}

export enum Categories {
  VARIABLE_PERCENT = 'Toate',
  VARIABLE_PERCENT_2 = 'Variabil 2',
  FIXED_PERCENT = 'Procent fix',
  FIXED_PRICE = 'Pret fix',
  SMALL_PRICE = 'Pret mic',
  DEFECT = 'Defect',
}

const cache = {}

export async function getCategory(name: Categories): Promise<TProductCategory> {
  if (cache[name]) return cache[name]

  const req = execute_kw('product.category', 'search_read', [[['name', 'ilike', name]]])
  cache[name] = req
  let results = await req

  if (!results?.data?.length) {
    execute_kw('product.category', 'create', [{name}])

    throw new Error(`No ${name} category found, attempted to create one, please refresh page`)
  }
  cache[name] = results.data[0]
  return cache[name]
}

export function useCategory(getter: () => Promise<TProductCategory>) {
  const [res, setRes] = useState<TProductCategory | null>(null)
  useEffect(() => {
    getter().then(setRes)
  }, [setRes, getter])

  return res
}

export const usePriceCategories = () => {
  const fixedPercent = useCategory(() => getCategory(Categories.FIXED_PERCENT))
  const variablePercent = useCategory(() => getCategory(Categories.VARIABLE_PERCENT))
  const fixedPrice = useCategory(() => getCategory(Categories.FIXED_PRICE))
  const smallPrice = useCategory(() => getCategory(Categories.SMALL_PRICE))
  const defect = useCategory(() => getCategory(Categories.DEFECT))
  const variablePercent2 = useCategory(() => getCategory(Categories.VARIABLE_PERCENT_2))

  return {
    fixedPercent,
    variablePercent,
    variablePercent2,
    fixedPrice,
    smallPrice,
    defect,
    loaded: fixedPercent?.id && variablePercent?.id && fixedPrice?.id && smallPrice?.id && defect?.id && variablePercent2?.id,
  }
}

const cache2 = {}

export async function getWebsiteCategory(name: string): Promise<TProductCategory> {
  if (cache2[name]) return cache2[name]

  const req = await execute_kw('product.public.category', 'search_read', [[['name', 'ilike', name]]])
  cache2[name] = req.data[0]
  return req.data[0]
}
