import axios from 'axios'
import useSWR from 'swr'
import {API_URL} from '../../config'
import {isDev} from '../env'

export const httpClient = axios.create({
  withCredentials: true,
  baseURL: API_URL,
})

// Add logging in DEV
if (isDev()) {
  httpClient.interceptors.request.use((request) => {
    console.log('[Axios] Starting Request', JSON.stringify(request, null, 2))
    return request
  })

  httpClient.interceptors.response.use((response) => {
    console.log('[Axios] Response:', JSON.stringify(response, null, 2))
    return response
  })
}

export async function execute_kw(model, fn, domain, parameters: any = undefined) {
  return httpClient.post('execute.php?ac=execute_kw', {
    model,
    function: fn,
    domain,
    parameters,
  })
}

const fetcher = (url) => httpClient.get(url).then((res) => res.data)

export function useGet<T>(key, config) {
  return useSWR<T>(key, fetcher, config)
}
