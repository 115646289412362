import {useState} from 'react'
import Button from '../components/Button'
import ProductSearch from '../components/ProductSearch'
import {API_URL} from '../config'
import Status from './Status'
import BatchEdit from '../components/BatchEdit'

export default function Main() {
  const [showStatus, setShowStatus] = useState(false)
  const [showBatch, setShowBatch] = useState(false)

  return (
    <div className="flex flex-col w-full items-center p-3 pt-14 text-primary bg-secondary">
      <hr />
      <div className="flex flex-row gap-4 absolute top-0 right-1 p-1">
        <a href={`${API_URL}/docs.php`} target="_blank" rel="noreferrer">
          <Button variant="link">Documentație</Button>
        </a>

        <Button onClick={() => setShowStatus((state) => !state)} variant="link">
          Stats
        </Button>
        <Button onClick={() => setShowBatch((state) => !state)}>Multi Edit</Button>
      </div>
      {showStatus && <Status />}
      {showBatch ? <BatchEdit /> : <ProductSearch />}
    </div>
  )
}
