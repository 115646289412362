import {useEffect} from 'react'
import Select from 'react-select'
import {TWarehouse} from '../lib/api/warehouse'

function compare(a?: TWarehouse, b?: TWarehouse) {
  return a?.lot_stock_id?.[0] === b?.lot_stock_id?.[0]
}

export default function WarehouseDropdown({
  warehouse,
  warehouses,
  setWarehouse,
  autoLoadFirst = false,
}: {
  warehouse?: TWarehouse
  warehouses?: TWarehouse[]
  setWarehouse: (warehouse?: TWarehouse) => unknown
  autoLoadFirst?: boolean
}) {
  useEffect(() => {
    if (!autoLoadFirst) return
    if (warehouses?.length) {
      setWarehouse(warehouses[0])
    }
  }, [warehouses, setWarehouse, autoLoadFirst])

  if (!warehouses?.length) return null

  return (
    <Select
      options={warehouses}
      getOptionLabel={(option) => option?.name || ''}
      getOptionValue={(option) => String(option?.lot_stock_id?.[0])}
      onChange={(val) => setWarehouse(val ? val : undefined)}
      placeholder="Selectează depozitul"
      value={warehouses.find((w) => compare(w, warehouse))}
    />
  )
}
