export function getImageDimensions(imageData): Promise<{w: number; h: number}> {
  return new Promise((resolve) => {
    const i = new Image()
    i.onload = () => resolve({w: i.width, h: i.height})
    i.src = imageData
  })
}

export function blobToDataURL(blob) {
  return new Promise<string>((resolve) => {
    var a = new FileReader()
    a.onload = (e) => resolve(e?.target?.result as string)
    a.readAsDataURL(blob)
  })
}
